import React from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from 'fs-toolkit-react';
import Wrapper from 'components/layout/Wrapper';

class Error extends React.PureComponent {
  render() {
    const {
      icon,
      title,
      message
    } = this.props;
    
    return (
      <Wrapper>
        <div className="content">
          <FontIcon icon={ icon } iconColor='#838383' iconSize='8rem' />
          <h3 className='content--mini__title'>{ title }</h3>
          <p className='large content--mini__message' dangerouslySetInnerHTML={{__html: message  }} />
        </div>
      </Wrapper>
    );
  }
}

Error.defaultProps = {
  icon: "faSadTearLight",
  title: "Unknown Error",
  message: "An unknown error has occurred."
}

Error.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
}

export default Error;