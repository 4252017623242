import React from 'react';
import Navigation from './Navigation';
import Main from './Main';
import Footer from './Footer';

class Wrapper extends React.PureComponent {
  render() {
    const { children} = this.props;
            
    return (
      <React.Fragment>
        <Navigation />
        <Main>
          { children }
        </Main>
        <Footer/>
      </React.Fragment>
    );
  }
}

export default Wrapper;