export default {
  // Registration
  RESET_REGISTRATION: "RESET_REGISTRATION",
  VALIDATE_REGISTRATION_PROCESSING: "VALIDATE_REGISTRATION_PROCESSING",
  VALIDATE_REGISTRATION_FAILURE: "VALIDATE_REGISTRATION_FAILURE",
  ALREADY_REGISTERED_FAILURE: "ALREADY_REGISTERED_FAILURE",
  VALIDATE_REGISTRATION_SUCCESS: "VALIDATE_REGISTRATION_SUCCESS",
  COMPLETE_REGISTRATION_PROCESSING: "COMPLETE_REGISTRATION_PROCESSING",
  COMPLETE_REGISTRATION_FAILURE: "COMPLETE_REGISTRATION_FAILURE",
  COMPLETE_REGISTRATION_SUCCESS: "COMPLETE_REGISTRATION_SUCCESS",
  REGISTRATION_FORM_CHANGE: "REGISTRATION_FORM_CHANGE",

  // Forgot Password
  RESET_FORGOT_PASSWORD: "RESET_FORGOT_PASSWORD",
  FORGOT_PASSWORD_FORM_CHANGE: "FORGOT_PASSWORD_FORM_CHANGE",
  FORGOT_PASSWORD_REQUEST_PROCESSING: "FORGOT_PASSWORD_REQUEST_PROCESSING",
  FORGOT_PASSWORD_REQUEST_FAILURE: "FORGOT_PASSWORD_REQUEST_FAILURE",
  FORGOT_PASSWORD_REQUEST_SUCCESS: "FORGOT_PASSWORD_REQUEST_SUCCESS",
  VALIDATE_PASSWORD_RESET_CODE_PROCESSING: "VALIDATE_PASSWORD_RESET_CODE_PROCESSING",
  VALIDATE_PASSWORD_RESET_CODE_FAILURE: "VALIDATE_PASSWORD_RESET_CODE_FAILURE",
  VALIDATE_PASSWORD_RESET_CODE_SUCCESS: "VALIDATE_PASSWORD_RESET_CODE_SUCCESS",
  RESET_PASSWORD_PROCESSING: "RESET_PASSWORD_PROCESSING",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  SET_FORGOT_PASSWORD_STATE: "SET_FORGOT_PASSWORD_STATE"
}