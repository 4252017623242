import React from 'react';
import { withRouter } from 'react-router';
import ResponsiveContext from 'contexts/responsive';

class Navigation extends React.PureComponent {
	render() {
    return (
      <ResponsiveContext.Consumer>
        {context => (
          <div className="navigation">
            <header className='navigation__main'>
              <div className="flex flex-align-center flex-space content">
                <img src={context.isMobile ? process.env.REACT_APP_MOBILE_LOGO : process.env.REACT_APP_DESKTOP_LOGO} alt="Full Sail University" className="logo" style={{ cursor: "pointer", maxWidth: '300px' }} onClick={() => this.props.history.push("/")}/>
                <p style={{marginBottom: '0'}}><a href="tel:+1-855-374-3572" style={{color: '#505050', fontWeight: '600'}}>1-855-374-3572</a></p>
              </div>
            </header>
          </div>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

export default withRouter(Navigation);