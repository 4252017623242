import React from 'react';

class Footer extends React.PureComponent {
  render() {
    return (
      <div className='wrapper__footer'>
        <footer>
          <img src="https://fsone.s3.amazonaws.com/fs-live.svg" alt="Full Sail Studios and Archway Illustration" />
        </footer>
      </div>
    );
  }
}

export default Footer;