import React from 'react';
import debounce from 'utils/debounce';

export default ComposedComponent => {
  class ComponentWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isMobile: this.isMobile(),
        windowInnerWidth: window.innerWidth
      };
    }

    static breakpoint = 960;

    isMobile = () => window.innerWidth < ComponentWrapper.breakpoint;

    handleResize = debounce(() => {
      this._isMounted &&
        this.setState({
          isMobile: this.isMobile(),
          windowInnerWidth: window.innerWidth
        });
    }, 250);

    setBreakpoint = breakpoint => {
      this.breakpoint = breakpoint;
    };

    componentDidMount() {
      this._isMounted = true;
      this._isMounted && this.setState({ isMobile: this.isMobile() });
      window.addEventListener('resize', this.handleResize, false);
    }

    componentWillUnmount() {
      this._isMounted = false;
      window.removeEventListener('resize', this.handleResize, false);
    }

    render = () => (
      <ComposedComponent
        {...this.props}
        isMobile={this.state.isMobile}
        windowInnerWidth={this.state.windowInnerWidth}
        breakpoint={this.setBreakpoint}
      />
    );
  }
  return ComponentWrapper;
};