import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  step: 'identity',
  emailAddress: '',
  firstName: '',
  stuNum: '',
  last4SSN: '',
  dob: '',
  password: '',
  passwordConfirm: '',
  termsAgreement: false,
  complete: false,
  processing: false
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.VALIDATE_REGISTRATION_PROCESSING:
      return state.set("processing", true);
    case ActionTypes.VALIDATE_REGISTRATION_FAILURE:
        return state.set("processing", false);
    case ActionTypes.ALREADY_REGISTERED_FAILURE:
      return state.merge({"processing": false, step: 'already_registered' });
    case ActionTypes.VALIDATE_REGISTRATION_SUCCESS:
      return state.merge({ processing: false, emailAddress: action.data.emailAddress, firstName: action.data.firstName, step: 'register' });
    case ActionTypes.COMPLETE_REGISTRATION_PROCESSING:
      return state.set("processing", true);
    case ActionTypes.COMPLETE_REGISTRATION_FAILURE:
      return state.set("processing", false);
    case ActionTypes.COMPLETE_REGISTRATION_SUCCESS:
      return state.merge({ 
        step: 'complete',
        processing: false,  
        firstName: '', 
        stuNum: '', 
        last4SSN: '',
        dob: '', 
        password: '',
        passwordConfirm: '',
        termsAgreement: false
      });
    case ActionTypes.REGISTRATION_FORM_CHANGE:
      return state.set(action.field, action.value);
    case ActionTypes.RESET_REGISTRATION:
      return initialState; 
    default:
      return state;
  }
}