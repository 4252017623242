import React from 'react';
import { Loader } from 'fs-toolkit-react';

const AsyncImporter = ({isLoading, error}) => {

  // handle the loading state
  if (isLoading) {
    return <Loader loaderFullPage/>;
  }
  else {
    return null;
  }
};

export default AsyncImporter;