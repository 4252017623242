import React from 'react';

class Main extends React.PureComponent {
  render() {
    const { children } = this.props;

    return (
      <div className='wrapper__main'>
        <main>
          { children }
          <p className="copyright medium center meta" dangerouslySetInnerHTML={{ __html: `Copyright © ${new Date().getFullYear()} ${process.env.REACT_APP_COPYRIGHT_ENTITY}` }} />
        </main>
      </div>
    );
  }
}

export default Main;