import ActionTypes from 'actions/actionTypes';
import { fromJS } from 'immutable';

const initialState = fromJS({
  step: 'request',
  emailAddress: '',
  codeDestination: '',
  code: '',
  password: '',
  passwordConfirm: '',
  complete: false,
  processing: false
});

export default function(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.FORGOT_PASSWORD_FORM_CHANGE:
      return state.set(action.field, action.value);
    case ActionTypes.FORGOT_PASSWORD_REQUEST_PROCESSING:
      return state.set("processing", true);
    case ActionTypes.FORGOT_PASSWORD_REQUEST_FAILURE:
      return state.set("processing", false);
    case ActionTypes.FORGOT_PASSWORD_REQUEST_SUCCESS:
      return state.merge({
        "processing": false, 
        "step": "verify", 
        "codeDestination": action.data.length ? action.data[0].emailAddress : ''
      });
    case ActionTypes.VALIDATE_PASSWORD_RESET_CODE_PROCESSING:
      return state.set("processing", true);
    case ActionTypes.VALIDATE_PASSWORD_RESET_CODE_FAILURE:
      return state.set("processing", false);
    case ActionTypes.VALIDATE_PASSWORD_RESET_CODE_SUCCESS:
      return state.merge({"processing": false, "step": "reset"});
    case ActionTypes.RESET_PASSWORD_PROCESSING:
      return state.set("processing", true);
    case ActionTypes.RESET_PASSWORD_FAILURE:
      return state.set("processing", false);
    case ActionTypes.RESET_PASSWORD_SUCCESS:
      return state.merge({"processing": false, "step": "complete"});
    case ActionTypes.SET_FORGOT_PASSWORD_STATE:
      return state.merge({ "step": action.step, "emailAddress": action.email, "code": action.code, "codeDestination": action.codeDestination }); 
    case ActionTypes.RESET_FORGOT_PASSWORD:
      return initialState; 
    default:
      return state;
  }
}