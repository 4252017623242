import { combineReducers } from 'redux-immutable';
import forgotPassword from './forgotPassword';
import registration from './registration';

const appReducers = {
  forgotPassword,
  registration
};

const reducer = combineReducers(appReducers);

export default reducer;
