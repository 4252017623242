import React from 'react';
import Error from './Error';
import Raven from 'raven-js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
     this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Raven.captureException(error);
  }

  render() {
    if (this.state.hasError) {
      return <Error />;
    }
    return this.props.children; 
  }
}

export default ErrorBoundary;