import React from 'react';
import cn from 'classnames';
import loadable from '@loadable/component';
import { ToastContainer, Zoom } from 'react-toastify';
import AsyncImporter from 'components/AsyncImporter';
import ResponsiveContext from 'contexts/responsive';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Error from 'components/handlers/Error';
import ErrorBoundary from 'components/handlers/ErrorBoundary';
import withResize from 'components/hoc/withResize';
import Raven from 'raven-js';

Raven.config(process.env.REACT_APP_SENTRY, { environment: process.env.REACT_APP_ENV }).install();

const AppWrapper = loadable(
  () => import('components/handlers/AppWrapper'),
  { fallback: <AsyncImporter /> }
);

class AccountMgmt extends React.Component {
  componentDidMount() {
    // Prevent the backspace key from navigating back.
    document.addEventListener('keydown', this.preventDeleteKeyNavigation);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.preventDeleteKeyNavigation);
  }

  preventDeleteKeyNavigation = (evt) => {
    if (evt.keyCode === 8) {
      evt.stopImmediatePropagation();
    }
  }

  render() {
    const stylesWrapper = [
      'wrapper',
      { 'wrapper--desktop desktop fs-d' : !this.props.isMobile },
      { 'wrapper--mobile mobile fs-m' : this.props.isMobile }
    ];

    return (
      <ErrorBoundary>
        <ToastContainer transition={Zoom} />
        <div className={ cn( stylesWrapper ) }>
          <ResponsiveContext.Provider value={{ isMobile: this.props.isMobile }}>
            <Router>
              <Switch>
                <Route exact path="/500" render={() => { return <Error title="Internal Server Error" message="<p>An error occurred while processing your request. Please try again or contact support.</p>" />}} />
                <Route exact path="/404" render={() => { return <Error title="Sorry, that page isn't here" message="<p>You didn't do anything wrong. We may have moved the page you're looking for somewhere else.</p>" />}} />
                <Route path="/" component={AppWrapper}/>
              </Switch>
            </Router>
          </ResponsiveContext.Provider>
        </div>
      </ErrorBoundary>
    );
  }
}

export default withResize(AccountMgmt);
