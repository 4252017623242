import { compose, applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import appReducer from 'reducers/index';

/* eslint-disable no-underscore-dangle */
const finalCreateStore = compose(
  applyMiddleware(thunk),
  window.devToolsExtension ? window.devToolsExtension({ trace: true, traceLimit: 25 }) : f => f
)(createStore);
/* eslint-enable */

export default function(initialState) {
  const store = finalCreateStore(appReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('reducers', () => {
      const nextRootReducer = require('reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

